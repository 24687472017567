<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Cuentas por pagar</span>
                </v-col>
                <v-col cols="2" class="pt-1">
                    <v-btn 
                        dark 
                        color="#023145" 
                        width="140" 
                        class="float-right" 
                        @click="handleShowFilters">
                        Filtros
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="debts"
                            item-key="id"
                            :items-per-page="10"
                            class="elevation-1"
                            height="63vh"
                            fixed-header
                            show-expand
                            :single-expand="true"
                            :loading="loading"
                            :options.sync="options"
                            :server-items-length="paginated.total"
                            loading-text="Loading... Please wait">
                        >
                            <template v-slot:top>
                                <span  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todas las cuentas por pagar
                                </span>
                                <v-divider></v-divider>
                            </template>

                            <template v-slot:item.register_date="{item}">
                                <span>{{ item.register_date | moment('DD/MM/YYYY') }}</span>
                            </template>

                            <template v-slot:item.total_amount="{item}">
                                <span class="amount-total">{{ item.total_amount | currency('S/') }}</span>
                            </template>

                            <template v-slot:item.payment_amount="{item}">
                                <span class="amount-payment">{{ item.payment_amount | currency('S/') }}</span>
                            </template>

                            <template v-slot:item.pending_amount="{item}">
                                <span class="amount-pending">{{ item.pending_amount | currency('S/') }}</span>
                            </template>

                            <template v-slot:item.status="{item}">
                                <span v-if="item.status == 'pendiente'"
                                    class="label-status transfer-anulada">
                                    {{ item.status }}
                                </span>
                                <span v-else
                                    class="label-status transfer-recibida">
                                    {{ item.status }}
                                </span>
                            </template>

                            <template v-slot:item.payment_date="{item}">
                                <span>{{ item.payment_date | moment('DD/MM/YYYY') }}</span>
                            </template>

                            <template v-slot:item.action="{item}">
                                <v-btn v-if="item.status == 'pendiente'"
                                    small 
                                    dark 
                                    color="blue" 
                                    @click="handleViewAmortizeForm(item)">
                                    Amortizar
                                </v-btn>
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    <template>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-card>
                                                        <v-simple-table dense class="table-kardex">
                                                            <thead class="table-kardex-head">
                                                                <tr>
                                                                    <th>Fecha</th>
                                                                    <th>Hora</th>
                                                                    <th>Monto</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="table-kardex-body">
                                                                <tr v-for="payment in item.payments" :key="payment.id">
                                                                    <td>{{ payment.created_at | moment('DD/MM/YYYY') }}</td>
                                                                    <td>{{ payment.created_at | moment('hh:mm a') }}</td>
                                                                    <td>
                                                                        {{ payment.payment_amount | currency('S/') }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </v-simple-table>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </template>
                                </td>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

        </template>

        <Filters ref="filters"></Filters>
        <Amortize ref="amortize"></Amortize>
    </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';

import Amortize from '../../../components/debts/AmortizeSupplier';
import Filters from '../../../components/debts/FiltersSuppliers';
import OfficeLogin from '../../../components/auth/OfficeLogin';

export default {
    name: 'DebtSupplier',
    components: {
        Amortize,
        Filters,
        OfficeLogin
    },
  watch: {
    options: {
      handler() {
        // console.log(this.options)
        this.perPage = this.options.itemsPerPage
        this.pageSelected = this.options.page
        const search = this.search_product
        this.loading = true
        if (search != null && search.trim().length > 0) {
          // this.perPage = -1
          this.getDebts({
            paginated: true, page: this.pageSelected, itemsPerPage: this.perPage,
            name: search
          }).then(res => this.loading = false)
              .catch(err => this.loading = false)
        } else {
          this.getDebts({paginated: true, page: this.pageSelected, itemsPerPage: this.perPage})
              .then(res => this.loading = false)
              .catch(err => this.loading = false)
        }
      },
      deep: true,
    }
  },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Cuentas',
                    disabled: true,
                    href: '',
                }
            ],
            headers: [
                { text: 'PROVEEDOR', value: 'supplier_name', sortable: false },
                { text: 'RUC', value: 'supplier_ruc', sortable: false },
                { text: 'INGRESO', value: 'register_date', sortable: false },
                { text: 'MONTO', value: 'total_amount', sortable: false, align: 'end' },
                { text: 'PAGADO', value: 'payment_amount', sortable: false, align: 'end' },
                { text: 'PENDIENTE', value: 'pending_amount', sortable: false, align: 'end' },
                { text: 'ESTADO', value: 'status', sortable: false },
                { text: 'FECHA DE PAGO', value: 'payment_date', sortable: false },             
                { text: '', value: 'action', sortable: false, align: 'end' }
            ],
          loading: false,
          dataTable: {page: 1},
          options: {},
          pageSelected: 1,
          perPage: 10,
          search_product: null
        }
    },
    computed: {
        ...mapState('supplier-accounts', [
            'debts',
            'paginated'
        ]),
        ...mapState('tills', [
            'selected_till'
        ])
    },
    methods: {
        ...mapActions('supplier-accounts', [
            'getDebts',
            'selectDebt'
        ]),
        ...mapActions('notifications', [
            'store_notification'
        ]),
        handleShowFilters () {
            this.$refs.filters.show();
        },
        handleViewAmortizeForm (debt) {
            if (this.selected_till.status == 'cerrada') {
                this.store_notification({
                    type: 'error',
                    message: 'Caja se encuentra cerrada.'
                });
            } else {
                this.selectDebt(debt);            
                this.$refs.amortize.show();
            }
        }
    },
    created () {
        // this.getDebts();
    }
}
</script>

<style scoped>
.amount-total{
    color: #3F51B5;
    font-weight: bold;
}

.amount-payment{
    color: #4CAF50;
    font-weight: bold;
}

.amount-pending{
    color: #FFC107;
    font-weight: bold;
}
</style>