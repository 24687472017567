<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>FILTRAR CUENTAS POR PAGAR</span>                         
        </div>
        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="filters.document"
                            label="RUC"
                            outlined
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-combobox
                            v-model="filters.status"
                            label="Estado de deuda"
                            :items="status"
                            outlined
                            hide-details
                            clearable>
                        </v-combobox>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleClearFilters">
                            <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
                            Quitar filtros
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="blue" block height="40" @click="handleSearch">
                            <v-icon size="16" class="mr-1">fas fa-search</v-icon>
                            Filtrar datos
                        </v-btn>
                    </v-col>
                </v-row>
            </template>            
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
    name: 'FiltersSuppliers',
    data () {
        return {
            drawer: false,
            menuFrom: false,
            menuUntil: false,
            status: ['PENDIENTE', 'PAGADA'],
            filters: {
                company_id: null,
                sede_id: null,
                office_id: null,
                ticket: null,
                document: null,
                status: null
            }
        }
    },
    computed: {
        ...mapState('authentication', [
            'company_id'
        ]),
        ...mapState('sedes', [
            'sedes'
        ]),
        ...mapState('offices', [
            'offices'
        ])
    },
    methods: {
        ...mapActions('offices', [
            'getOffices',
            'run_set_offices'
        ]),
        ...mapActions('sales', [
            'getOrders'
        ]),
        ...mapActions('supplier-accounts', [
            'getDebts'
        ]),
        show () {
            this.filters.company_id = this.company_id;
            this.drawer = !this.drawer;
        },
        customText(item){
            let text = item.name;
            
            return text;
        },
        closeForm () {
            this.drawer = false;
        },
        clearData () {
            this.filters.sede_id = null;
            this.filters.office_id = null;
            this.filters.document = null;
            this.filters.ticket = null;
            this.filters.status = null;
        },
        handleClearFilters () {
            this.clearData();
            this.getDebts(this.filters);
        },
        async handleSearch () {
            await this.getDebts(this.filters);
            this.closeForm();
        }
    }
}
</script>
